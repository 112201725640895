import PropTypes from 'prop-types'
import styled from 'styled-components'
import ContentWrapper from './content-wrapper'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { useInView } from 'react-intersection-observer'
import { useSpring, animated } from 'react-spring'
import { getColorVariable } from '../utils'

const Container = styled.section`
  margin-top: -2rem;
  padding: 4rem 0;
  border-radius: 2rem 2rem 0 0;

  ${({ background }) => `background: var(--color-${background});`}

  @media (min-width: 48em) {
    padding: 4rem 0 6rem;
  }

  @media (min-width: 62.5em) {
    padding: 6rem 0 8rem;
  }
`

const Grid = styled.div`
  display: grid;
  grid-gap: 2.4rem;

  @media (min-width: 48em) {
    grid-template-columns: 1fr 1fr;
  }
`

const TextPanel = styled.div`
  background: var(--color-primary-1);
  border-radius: 2rem;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--color-white);

  ${({ tan }) =>
    tan &&
    `
      background: var(--color-primary-3);
      color: var(--color-black);
    `}

  p {
    font-size: 2;
    font-weight: 500;
    line-height: 1.125;

    &:last-child {
      margin: 0;
    }
  }

  @media (min-width: 62.5em) {
    padding: 4rem 4.8rem;

    p {
      font-size: 2.4rem;
    }
  }

  @media (min-width: 75em) {
    padding: 4rem 6rem;
  }
`

const Heading = styled.h2`
  color: inherit;
  font-size: 1.9rem;
  margin: 0 0 2em;
  line-height: 1.2;
`

const StatisticsPanel = styled.div`
  border-radius: 2rem;
  background-color: var(--color-white);
  padding: 3rem 2rem;
  color: var(--color-black);

  p {
    &:last-child {
      margin: 0;
    }
  }

  @media (min-width: 62.5em) {
    padding: 4rem 4.8rem;
  }

  @media (min-width: 75em) {
    padding: 4rem 7rem 4rem 6rem;
  }
`

const Circle1 = styled.div`
  width: 80%;
  max-width: 34rem;
  background: var(--color-primary-2);
  border-radius: 50%;
  margin: 5rem auto;
  position: relative;

  &::after {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const Circle2 = styled(animated.div)`
  background: var(--color-secondary-2);
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  transform-origin: 100% 50%;

  .no-js & {
    transform: translate(0, -50%) scale(1) !important;
  }
`

const Circle1Sbs = styled.div`
  background: var(--color-primary-1);
  border-radius: 50%;
  color: white;
  position: relative;

  &::after {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const Circle2Sbs = styled(animated.div)`
  background: var(--color-secondary-1);
  border-radius: 50%;
  position: relative;
  color: white;
  position: relative;

  .no-js & {
    transform: scale(1) !important;
  }

  &::after {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const CirclesSbs = styled.div`
  display: flex;
  align-items: center;
  margin: 5rem 0;
  max-width: 80%;
`

const Label = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -0.8rem;
  line-height: 1;
  z-index: 1;
  transition: opacity 600ms 150ms;

  .no-js & {
    opacity: 1 !important;
  }
`

const Statistics = ({
  title,
  text,
  statisticsTitle,
  statisticsText,
  chartLayout,
  label1,
  label2,
  comparisonSize,
  backgroundColor,
}) => {
  const [containerRef, inView] = useInView({
    threshold: 1.0,
    triggerOnce: true,
  })

  const size = (Math.sqrt(comparisonSize / Math.PI) * 2) / (Math.sqrt(100 / Math.PI) * 2)

  const transform = chartLayout === 'Overlay' ? 'translate(0, -50%)' : ''

  const spring = useSpring({
    config: { mass: 1, tension: 100, friction: 10, precision: 0.001 },
    transform: inView ? `${transform} scale(1)` : `${transform} scale(0)`,
  })

  return (
    <Container background={getColorVariable(backgroundColor || 'Rose white')}>
      <ContentWrapper>
        <Grid>
          <TextPanel tan={backgroundColor === 'Burgundy'}>
            <Heading>{title}</Heading>
            <div>{text && renderRichText(text)}</div>
          </TextPanel>

          <StatisticsPanel>
            <Heading>{statisticsTitle}</Heading>

            {chartLayout === 'Overlay' ? (
              <Circle1 ref={containerRef}>
                <Label style={{ left: '1.2rem' }}>{label1}</Label>
                <Label style={{ right: '1.2rem', opacity: inView ? 1 : 0 }}>{label2}</Label>

                <Circle2 style={{ width: `${size * 100}%`, height: `${size * 100}%`, ...spring }} />
              </Circle1>
            ) : (
              <CirclesSbs ref={containerRef}>
                <Circle1Sbs style={{ width: `${(1 / (1 + size)) * 100}%` }}>
                  <Label style={{ right: '1.2rem' }}>{label1}</Label>
                </Circle1Sbs>

                <Circle2Sbs style={{ width: `${(size / (1 + size)) * 100}%`, ...spring }}>
                  <Label style={{ right: '1.2rem', opacity: inView ? 1 : 0 }}>{label2}</Label>
                </Circle2Sbs>
              </CirclesSbs>
            )}

            {statisticsText && renderRichText(statisticsText)}
          </StatisticsPanel>
        </Grid>
      </ContentWrapper>
    </Container>
  )
}

Statistics.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object,
  statisticsTitle: PropTypes.string.isRequired,
  statisticsText: PropTypes.object,
  chartLayout: PropTypes.string,
  label1: PropTypes.string,
  label2: PropTypes.string,
  comparisonSize: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string,
}

export default Statistics

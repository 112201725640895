import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ContentWrapper from './content-wrapper'
import HistoryEvent from './history-event'

const Container = styled.div`
  margin-bottom: 30vh;
`

const HistoryTimeline = ({ events }) => {
  const [openIndexes, setOpenIndexes] = useState([])
  const [lastIndex, setLastIndex] = useState(0)
  const [activeIndex, setActiveIndex] = useState()

  const el = useRef()

  useEffect(() => {
    let observer

    const timeout = setTimeout(() => {
      if (!openIndexes.length) {
        setOpenIndexes([0])
        setActiveIndex(0)
      } else {
        const children = Array.from(el.current.children)

        observer = new IntersectionObserver(
          entries => {
            const [entry] = entries.filter(({ isIntersecting }) => isIntersecting)

            if (entry) {
              const index = children.indexOf(entry.target)

              if (lastIndex < index) {
                setLastIndex(index)
              }

              observer.unobserve(entry.target)
            }
          },
          {
            rootMargin: '0% 0% -35% 0%',
          }
        )

        children.map((child, i) => {
          if (!openIndexes.includes(i)) {
            observer.observe(child)
          }
        })
      }
    }, 500)

    return () => {
      clearTimeout(timeout)

      if (observer) {
        observer.disconnect()
      }
    }
  }, [lastIndex, openIndexes])

  useEffect(() => {
    if (openIndexes.length) {
      if (!openIndexes.includes(lastIndex)) {
        const index = openIndexes[openIndexes.length - 1] + 1
        setActiveIndex(index)
        setOpenIndexes([...openIndexes, index])
      }
    }
  }, [lastIndex, openIndexes])

  useEffect(() => {
    const children = Array.from(el.current.children)
    let last = window.scrollY
    let scrollDirection

    const observer = new IntersectionObserver(
      entries => {
        const [entry] = entries.filter(({ isIntersecting }) => isIntersecting)

        if (entry && scrollDirection === 'up') {
          const index = children.indexOf(entry.target)

          if (openIndexes.includes(index)) {
            setActiveIndex(index)
          }
        }
      },
      {
        rootMargin: '-50% 0% 0% 0%',
      }
    )

    const observer2 = new IntersectionObserver(
      entries => {
        const interSectingEntries = entries.filter(({ isIntersecting }) => isIntersecting)

        const entry = interSectingEntries[interSectingEntries.length - 1]

        if (entry && scrollDirection === 'down') {
          const index = children.indexOf(entry.target)

          if (openIndexes.includes(index)) {
            setActiveIndex(index)
          }
        }
      },
      {
        rootMargin: '0% 0% -35% 0%',
      }
    )

    children.map(child => {
      observer.observe(child)
      observer2.observe(child)
    })

    const onScroll = () => {
      scrollDirection = last > window.scrollY ? 'up' : 'down'
      last = scrollY
    }

    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
      observer.disconnect()
      observer2.disconnect()
    }
  }, [openIndexes])

  return (
    <Container>
      <ContentWrapper>
        <div ref={el}>
          {events.map(({ title, text, year, media }, i) => (
            <HistoryEvent
              key={i}
              title={title}
              text={text}
              year={year}
              media={media}
              isOpen={openIndexes.includes(i)}
              isActive={activeIndex === i}
            />
          ))}
        </div>
      </ContentWrapper>
    </Container>
  )
}

HistoryTimeline.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
}

export default HistoryTimeline

import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import Img from 'gatsby-image'
import styled, { keyframes } from 'styled-components'
import useResizeAware from 'react-resize-aware'
import ContentWrapper from './content-wrapper'
import { Mouse } from './icons'

const pulse = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const Container = styled.section`
  height: 300vh;
  position: relative;
  color: white;
  background: var(--color-secondary-3);
  margin-top: -0.1rem;
  z-index: 10;

  .no-js & {
    height: 100vh;
  }
`

const Content = styled.div`
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImageHolder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const BgImage = styled(Img)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.03);
  filter: blur(1.5rem);
`

const Image = styled(Img)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.03);
  filter: blur(1.5rem);
  will-change: filter;

  // Disable blur filter in Firefox since it way to slow
  @-moz-document url-prefix() {
    filter: none !important;
  }

  .no-js & {
    filter: none;
  }
`

const Heading = styled.h1`
  font-family: Canela, serif;
  position: relative;
  font-size: var(--typography-1);
  font-weight: normal;
  text-align: center;
  line-height: 1;
  margin: 0 auto;
  color: white;
  position: sticky !important;
  bottom: 50vh;

  > span {
    display: block;
    will-change: opacity;

    .js & {
      opacity: 0;
    }
  }

  @media (max-width: 26em) {
    font-size: 2.6rem;
  }
`

const Icon = styled.div`
  position: fixed;
  left: 50%;
  bottom: 4rem;
  margin-left: -1.2rem;
  width: 2.4rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  will-change: opacity;

  .no-js & {
    position: absolute;
  }
`

const IconText = styled.div`
  white-space: nowrap;
  animation: ${pulse} 1s infinite alternate;
`

const StyledMouse = styled(Mouse)`
  margin-top: 1.5rem;
`

const Hero = ({ media, title }) => {
  const [resizeListener, sizes] = useResizeAware()
  const imageRef = useRef()
  const text1Ref = useRef()
  const text2Ref = useRef()
  const iconRef = useRef()

  media.fluid.sizes = '100vw'

  const titleLength = title.length
  const titleParts = title.split(' ')
  const titlePartLength = titleParts.length
  let titleTop = ''

  for (let i = 0; i < titlePartLength; i += 1) {
    if (titleTop.length < titleLength / 2) {
      titleTop += `${titleParts.shift()} `
    } else {
      break
    }
  }

  const titleBottom = titleParts.join(' ')

  useEffect(() => {
    const onScroll = () => {
      const progress = Math.min(1, window.scrollY / (sizes.height - window.innerHeight))
      const blurProgress = 1 - Math.max(0.00001, progress - 0.5) * 2
      const text1Progress = Math.sin(Math.PI * progress * 1.2)
      const text2Progress = Math.sin(Math.PI * progress * 1.4 - 1)

      imageRef.current.children[0].style.filter = `blur(${blurProgress * 1.5}rem)`
      text1Ref.current.style.opacity = text1Progress
      text2Ref.current.style.opacity = text2Progress
      iconRef.current.style.opacity = Math.max(0, 1 - progress * 15)
    }

    document.addEventListener('scroll', onScroll)
    onScroll()

    return () => document.removeEventListener('scroll', onScroll)
  }, [sizes.height])

  media.fluid.sizes = '50vw'

  return (
    <Container>
      {resizeListener}

      <Content>
        <ImageHolder>
          <BgImage fluid={media.fluid} />
        </ImageHolder>

        <ImageHolder ref={imageRef}>
          <Image fluid={media.fluid} />
        </ImageHolder>

        <ContentWrapper>
          <Heading>
            <span ref={text1Ref}>{titleTop}</span> <span ref={text2Ref}>{titleBottom}</span>
          </Heading>
        </ContentWrapper>
      </Content>
      <Icon ref={iconRef}>
        <IconText>Scrolla</IconText> <StyledMouse />
      </Icon>
    </Container>
  )
}

Hero.propTypes = {
  media: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.object,
}

export default Hero

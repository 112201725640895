import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import ContentWrapper from './content-wrapper'
import { getColorVariable } from '../utils'
import { Play } from './icons'

const youtubeRegexp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/

const Container = styled.section`
  margin: 0 auto;
  border-radius: 2rem 2rem 0 0;
  padding: 4rem 0 6rem;

  ${({ background }) => `background: var(--color-${background});`}
  ${({ noPaddingTop }) => noPaddingTop && `padding-top: 0;`}

  @media (min-width: 62.5em) {
    padding: 6.4rem 0 8.4rem;

    ${({ noPaddingTop }) => noPaddingTop && `padding-top: 0;`}
    ${({ noPaddingBottom }) => noPaddingBottom && `padding-bottom: 2rem;`}
  }
`

const Player = styled.div`
  position: relative;
  padding-bottom: 56.25%;

  > iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`

const Button = styled.button`
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  width: 100%;
  position: relative;
`

const CorneredImage = styled(Img)`
  border-radius: 2rem;
`

const PlayButton = styled(Play)`
  width: 6rem;
  height: 6rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-secondary-1);
  opacity: 0.9;

  @media (min-width: 48em) {
    width: 8rem;
    height: 8rem;
  }

  @media (min-width: 62.5em) {
    width: 10rem;
    height: 10rem;
  }
`

const VideoPlayer = ({ videoId, isVisible }) => {
  return (
    <Player>
      <iframe
        width="1280"
        height="720"
        src={`https://www.youtube-nocookie.com/embed/${videoId}${
          isVisible ? '?rel=0&autoplay=1' : ''
        }`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ background: 'black' }}
      />
    </Player>
  )
}

const Video = ({
  video,
  videoThumbnail,
  backgroundColor,
  removeTopPadding,
  removeBottomPadding,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [, videoId] = video.match(youtubeRegexp)
  videoThumbnail.sizes = '(min-width: 90em) 90em, 100vw'

  console.log(videoId)

  const onButtonClick = () => {
    setIsVisible(true)
  }

  return (
    <Container
      background={getColorVariable(backgroundColor || 'Rose white')}
      noPaddingTop={removeTopPadding}
      noPaddingBottom={removeBottomPadding}
    >
      <ContentWrapper>
        {isVisible ? (
          <VideoPlayer videoId={videoId} isVisible={isVisible} />
        ) : (
          <Button onClick={onButtonClick}>
            <CorneredImage fluid={{ ...videoThumbnail.fluid, aspectRatio: 16 / 9 }} />
            <PlayButton />
          </Button>
        )}
      </ContentWrapper>
    </Container>
  )
}

VideoPlayer.propTypes = {
  videoId: PropTypes.string,
  isVisible: PropTypes.bool,
}

Video.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  video: PropTypes.string,
  videoThumbnail: PropTypes.object,
  backgroundColor: PropTypes.string,
  removeTopPadding: PropTypes.bool,
  removeBottomPadding: PropTypes.bool,
}

export default Video

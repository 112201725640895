import PropTypes from 'prop-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import styled from 'styled-components'
import ContentWrapper from './content-wrapper'
import SectionTop from './section-top'
import ActiveInView from './active-in-view'
import ButtonLink from './button-link'
import { getIconFromString } from './icons'
import { fadeInUp } from '../styles/animations'
import { getColorVariable } from '../utils'

const Container = styled.section`
  margin-top: -2rem;
  background: var(--color-primary-1);
  border-radius: 2rem 2rem 0 0;
  padding: 4rem 0 4.4rem;

  ${({ background }) => `background: var(--color-${background});`}

  ${({ background }) =>
    background === 'primary-1' &&
    `
    color: white;

    h2, h3 {
      color: inherit;
    }

  `}

  @media (min-width: 48em) {
    padding: 6rem 0 4.4rem;
  }

  @media (min-width: 62.6em) {
    padding: 8rem 0 9rem;
  }
`

const Content = styled(ActiveInView)`
  display: grid;
  grid-gap: 2.5rem;
  margin: 4rem 0 0 0;

  @media (min-width: 48em) {
    margin: 7rem 0 0 0;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const Bottom = styled.div`
  margin-top: 3rem;

  @media (min-width: 48em) {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translate(-50%, 0);
  }

  @media (min-width: 62.5em) {
    bottom: 4rem;
  }
`

const Item = styled.div`
  border-radius: 2rem;
  text-align: center;
  position: relative;
  opacity: 0;

  .no-js & {
    opacity: 1;
  }

  .is-active & {
    animation: ${fadeInUp} 500ms forwards;
  }

  ${({ whiteBg }) =>
    whiteBg &&
    `
    padding: 3rem 2rem;
    color: var(--color-body);
    background: white;

    h3, ${Bottom} {
      color: var(--color-black);
    }

  `}

  @media (min-width: 48em) {
    ${({ bottomPadded }) =>
      bottomPadded &&
      `
      padding-bottom: 10rem;
    `}
  }

  @media (min-width: 62.5em) {
    ${({ whiteBg }) =>
      whiteBg &&
      `
        padding-left: 5rem;
        padding-right: 5rem;
      `}

    ${({ bottomPadded }) =>
      bottomPadded &&
      `
      padding-bottom: 14rem;
    `}
  }
`

const Icon = styled.div`
  width: 6rem;
  height: 6rem;
  background: var(--color-primary-1);
  border-radius: 2rem;
  color: white;
  margin: 0 auto 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 57%;
  }

  @media (min-width: 62.5em) {
    margin-bottom: 3.5rem;
    width: 8.8rem;
    height: 8.8rem;
  }
`

const Heading = styled.h3`
  margin: 0 auto 1.263em;
  max-width: 38rem;

  @media (min-width: 62.5em) {
    font-size: 1.9rem;
  }
`

const Text = styled.div`
  max-width: 38rem;
  margin: 0 auto;
`

const Content3Columns = ({ title, text, content, backgroundColor, whiteColumns }) => {
  const hasButtons = content.some(
    ({ callToActionText, callToActionLink }) => callToActionText && callToActionLink
  )

  return (
    <Container background={getColorVariable(backgroundColor || 'White')}>
      <ContentWrapper>
        <SectionTop title={title} text={text} />

        <Content>
          {content.map(({ title, text, icon, callToActionText, callToActionLink }, i) => (
            <Item
              key={i}
              bottomPadded={hasButtons}
              whiteBg={whiteColumns}
              style={{ animationDelay: `${i * 100}ms` }}
            >
              {icon && <Icon>{getIconFromString(icon)}</Icon>}
              {!icon && <Heading>{title}</Heading>}
              <Text>
                {renderRichText(text)}

                {callToActionText && callToActionLink && (
                  <Bottom>
                    <ButtonLink href={callToActionLink}>{callToActionText}</ButtonLink>
                  </Bottom>
                )}
              </Text>
            </Item>
          ))}
        </Content>
      </ContentWrapper>
    </Container>
  )
}

Content3Columns.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object,
  content: PropTypes.arrayOf(PropTypes.object),
  backgroundColor: PropTypes.string,
  whiteColumns: PropTypes.bool,
}

export default Content3Columns

import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'
import useResizeAware from 'react-resize-aware'
import { useSpring, animated } from 'react-spring'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const Container = styled.div`
  position: relative;
  padding-bottom: 2rem;

  @media (min-width: 62.5em) {
    display: grid;
    grid-auto-flow: dense;
    min-height: 8.5rem;
    grid-gap: 2.5rem;
    grid-template-columns: 1fr 8.5rem 12rem;
    padding-bottom: 0;
  }
`

const Indicator = styled.div`
  display: none;
  border-radius: 100rem;
  background: var(--color-primary-1);
  transition: background-color 600ms;

  ${({ isActive }) => isActive && `background: var(--color-secondary-1);`}

  @media (min-width: 62.5em) {
    display: block;
  }
`

const Year = styled.div`
  line-height: 1.25;
  font-weight: 500;
  grid-column: 3;

  @media (min-width: 62.5em) {
    display: block;
    align-self: center;
    font-weight: 400;

    ${({ isBold }) => isBold && `font-weight: 500;`}
  }

  @media (max-width: 62.4375em) {
    position: sticky;
    top: 7.5rem;
    background: var(--color-secondary-1);
    color: white;
    border-radius: 2.4rem;
    padding: 1.4rem 0 1.4rem 1.6rem;
    z-index: 1;
    display: flex;

    div:first-child {
      flex-shrink: 0;
      white-space: nowrap;
    }

    div:last-child {
      text-align: center;
      flex-grow: 1;
      font-weight: 400;
      padding: 0 1.6rem;
    }
  }
`

const Panel = styled(animated.div)`
  height: 0;
  overflow: hidden;

  @media (max-width: 62.4375em) {
    height: auto !important;
    opacity: 1 !important;
    padding-bottom: 2rem;
  }
`

const Content = styled.div`
  position: relative;

  @media (min-width: 62.5em) {
    display: flex;
    align-items: center;
  }
`

const ImageHolder = styled.div`
  padding: 2rem 0;

  @media (min-width: 62.5em) {
    width: 50%;
  }
`

const Image = styled(Img)`
  border-radius: 2rem;
`

const Text = styled.div`
  h3 {
    font-size: inherit;
    margin: 0 0 1em;
  }

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 62.5em) {
    padding: 2rem 2rem 2rem 4rem;
    width: 50%;
  }

  @media (min-width: 75em) {
    padding: 2rem 4rem 2rem 8rem;

    h3 {
      font-size: 2.4rem;
      line-height: 1.17;
    }
  }
`

const HistoryEevent = ({ title, text, year, media, isOpen, isActive }) => {
  const [resizeListener, sizes] = useResizeAware()
  const spring = useSpring({
    height: isOpen && sizes.height ? sizes.height : 0,
    opacity: isOpen ? 1 : 0,
  })

  return (
    <Container>
      <Year isBold={isActive}>
        <div>{year}</div>
        <div>{title}</div>
      </Year>
      <Panel style={spring}>
        <Content>
          {resizeListener}

          <ImageHolder>{media && <Image fluid={media.fluid} />}</ImageHolder>
          <Text>
            <div>{renderRichText(text)}</div>
          </Text>
        </Content>
      </Panel>
      <Indicator isActive={isActive} />
    </Container>
  )
}

HistoryEevent.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  year: PropTypes.number.isRequired,
  media: PropTypes.object,
  isOpen: PropTypes.bool,
  isActive: PropTypes.bool,
}

export default HistoryEevent

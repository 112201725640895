import PropTypes from 'prop-types'
import styled from 'styled-components'
import ContentWrapper from './content-wrapper'
import ActiveInView from './active-in-view'
import { fadeInUp } from '../styles/animations'
import { getColorVariable } from '../utils'

const Container = styled.section`
  padding: 5rem 0;
  border-radius: 2rem 2rem 0 0;
  margin-top: -2rem;

  ${({ background }) => `background: var(--color-${background});`}

  @media (min-width: 62.5em) {
    padding: 16rem 0 13rem;
  }
`

const Content = styled(ActiveInView)`
  line-height: 1;
  color: var(--color-primary-1);
  text-align: center;
  max-width: 88rem;
  opacity: 0;
  padding: 0;

  .no-js & {
    opacity: 1;
  }

  &.is-active {
    animation: ${fadeInUp} 500ms forwards;
  }

  @media (min-width: 62.5em) {
    margin: 0 auto;
  }
`

const Text = styled.p`
  margin: 0 0 0.5em;
  font-size: 2.4rem;
  font-family: Canela, serif;

  @media (min-width: 48em) {
    font-size: 3.2rem;
  }

  @media (min-width: 62.5em) {
    font-size: 4.8rem;
  }
`

const Cite = styled.cite`
  font-style: normal;

  &::before {
    content: '— ';
  }

  @media (min-width: 62.5em) {
    font-size: 2.2rem;
  }
`

const Quote = ({ quote, name, backgroundColor }) => {
  if (!quote) {
    return null
  }

  return (
    <Container background={getColorVariable(backgroundColor || 'White')}>
      <ContentWrapper>
        <Content is="blockquote">
          <Text>{quote.quote}</Text>
          <Cite>{name}</Cite>
        </Content>
      </ContentWrapper>
    </Container>
  )
}

Quote.propTypes = {
  quote: PropTypes.object,
  name: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
}

export default Quote

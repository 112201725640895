import PropTypes from 'prop-types'
import styled from 'styled-components'
import ContentWrapper from './content-wrapper'
import Card from './card'
import { getColorVariable } from '../utils'

const Container = styled.section`
  margin-top: -2rem;
  padding: 2rem 0;
  border-radius: 2rem 2rem 0 0;

  ${({ background }) => `background: var(--color-${background});`}

  @media (min-width: 62.5em) {
    padding: 4rem 0;
  }

  @media (min-width: 90em) {
    padding: 6.4rem 0;
  }
`

const Cards = ({ cards, backgroundColor }) => {
  cards.map(
    ({ media }) =>
      media &&
      media.map(({ fluid }) => {
        fluid.sizes = '(min-width: 87em) 41.25em, (min-width: 48em) 50vw, 100vw'
      })
  )

  return (
    <Container background={getColorVariable(backgroundColor || 'White')}>
      <ContentWrapper>
        {cards.map(card => (
          <Card key={card.id} {...card} />
        ))}
      </ContentWrapper>
    </Container>
  )
}

Cards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  backgroundColor: PropTypes.string,
}

export default Cards

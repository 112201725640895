export const Mouse = props => (
  <svg {...props} width="26" height="50" viewBox="0 0 26 50" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 13a13 13 0 00-26 0v24a13 13 0 0026 0V13zm-2 0v24a11 11 0 01-22 0V13a11 11 0 0122 0z" />
    <path d="M12 12v13a1 1 0 002 0V12a1 1 0 00-2 0z" />
  </svg>
)

export const Search = props => (
  <svg
    {...props}
    width="15"
    height="15"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path d="M10.84 11.55a6.56 6.56 0 11.71-.71L15 14.29l-.71.71-3.45-3.45zm-4.28.57A5.55 5.55 0 116.56 1a5.55 5.55 0 010 11.1z" />
  </svg>
)

export const Arrow = props => (
  <svg viewBox="0 0 14 13" width="13" height="13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M.5 7a.5.5 0 010-1h10.62L5.97.85a.5.5 0 01.7-.7l6.36 6.35-6.35 6.35a.5.5 0 01-.71-.7L11.12 7H.5z" />
  </svg>
)

export const Sustainability = props => (
  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M24.75 43.23A18.27 18.27 0 017.89 17.99 18.26 18.26 0 0122 6.93a1.75 1.75 0 00-.52-3.46 21.76 21.76 0 00-.98 42.84c1.4.28 2.82.41 4.24.41a1.75 1.75 0 000-3.5zm8.5-2.1a18.28 18.28 0 01-4.4 1.63 1.75 1.75 0 00.8 3.4 21.75 21.75 0 005.23-1.94 1.75 1.75 0 00-1.63-3.1zm6.67-6.02a18.25 18.25 0 01-3.1 3.55 1.75 1.75 0 102.31 2.63 21.71 21.71 0 003.7-4.23 1.75 1.75 0 10-2.9-1.95zm5.4-3.07a21.77 21.77 0 001.15-5.98 1.75 1.75 0 00-3.5-.17c-.08 1.71-.4 3.4-.96 5.02a1.75 1.75 0 003.3 1.13z" />
    <path d="M41.05 16.77a18.2 18.2 0 00-1.12-1.94l-.05-.07c-2-2.97-4.84-5.28-8.14-6.65l-.09-.04a18.27 18.27 0 00-5.53-1.3h-.02a1.75 1.75 0 01.28-3.49h.11c2.25.19 4.48.72 6.59 1.6l.09.03a21.75 21.75 0 019.67 7.97l.05.09c.43.65.83 1.32 1.18 2l1.53-4.57a1.75 1.75 0 013.31 1.13l-2.66 7.98-.01.04a2.75 2.75 0 01-3.65 1.63l-7.53-3.1a1.75 1.75 0 011.36-3.22l4.63 1.9z" />
    <path d="M26.5 23.9l8.03 4a1.75 1.75 0 01-1.56 3.14l-9-4.5c-.6-.3-.97-.9-.97-1.56v-13a1.75 1.75 0 013.5 0v11.91z" />
  </svg>
)

export const Bee = props => (
  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M28.3 8.95l.92-3.45a1.75 1.75 0 013.38.9l-.92 3.46c1.84.73 3.56 1.84 5.05 3.32l.27.28a14.92 14.92 0 012.95 4.5l3.32-.88a1.75 1.75 0 01.9 3.38l-3.26.87a14.98 14.98 0 01-25.4 13.07A15 15 0 0128.3 8.95zM15 20.81A11.5 11.5 0 0029.1 34.9L15 20.8zm4.72-6.59a11.58 11.58 0 00-3.18 3.18l15.96 15.96a11.5 11.5 0 003.18-3.19L19.74 14.22zm17.5 12.54a11.5 11.5 0 00-2.98-11.1l-.21-.2a11.46 11.46 0 00-7.92-3.17h-.28c-.9.03-1.81.16-2.7.4l14.09 14.08zM44.17 48.45c-4.98 1.37-9.54-4.16-6.82-9.03l5.05-9.04a1.75 1.75 0 013.22.4l2.73 10.19a6.06 6.06 0 01-4.17 7.47h-.01zm-.81-12.6l-2.96 5.28c-1.16 2.1.7 4.53 2.84 3.94h.03c1.3-.35 2.08-1.77 1.7-3.2l-1.61-6.02zM1.65 5.73a6.06 6.06 0 017.47-4.17l10.2 2.73a1.75 1.75 0 01.4 3.22l-9.05 5.05C5.8 15.28.26 10.72 1.63 5.74v-.01zm12.6.82L8.21 4.94c-1.43-.38-2.85.4-3.2 1.7v.03c-.6 2.14 1.85 4 3.94 2.84l5.28-2.96z" />
  </svg>
)

export const Rain = props => (
  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M39.52 15.64A15.01 15.01 0 0025 4.38a15.01 15.01 0 00-14.52 11.27A9.34 9.34 0 00.53 25a9.4 9.4 0 009.41 9.37 1.88 1.88 0 100-3.75A5.64 5.64 0 014.3 25c0-3.1 2.54-5.62 5.65-5.62.46 0 .94.07 1.53.24a1.88 1.88 0 002.39-1.58A11.24 11.24 0 0125 8.13c5.68 0 10.47 4.26 11.15 9.91a1.88 1.88 0 002.38 1.58 5.56 5.56 0 011.53-.24A5.64 5.64 0 0145.7 25c0 3.1-2.54 5.62-5.65 5.62a1.88 1.88 0 100 3.75A9.4 9.4 0 0049.47 25a9.33 9.33 0 00-9.95-9.36z" />
    <path d="M25.6 30.72c-1-.33-2.06.2-2.39 1.19l-3.76 11.25a1.87 1.87 0 001.79 2.46c.78 0 1.52-.5 1.78-1.28l3.76-11.25c.33-.98-.2-2.04-1.19-2.37zM18.06 30.72c-1-.33-2.05.2-2.38 1.19l-3.76 11.25a1.87 1.87 0 001.78 2.46c.79 0 1.52-.5 1.79-1.28l3.76-11.25c.33-.98-.2-2.04-1.19-2.37zM33.12 30.72c-.99-.33-2.05.2-2.38 1.19l-3.76 11.25a1.87 1.87 0 001.78 2.46c.79 0 1.52-.5 1.79-1.28l3.76-11.25c.33-.98-.2-2.04-1.19-2.37z" />
  </svg>
)

export const Care = props => (
  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M25 45.16l-1.15-.62C12.3 38.29 6.36 31.59 3.77 25.55c-2.6-6.09-1.76-11.4.05-14.5l.06-.1C6.5 6.54 10.7 4.32 15.2 5.06c3.58.58 6.98 2.98 9.7 6.95 2.47-4 5.9-6.43 9.57-7.03 4.62-.77 9.13 1.44 11.76 6.08C49.78 17.3 49.48 32.4 25.9 44.7l-.9.46zM36.19 8.64a7 7 0 00-1.1.1c-2.92.48-6.18 2.87-8.25 7.84l-.05.12a1.9 1.9 0 01-3.46.13l-.06-.12c-2.57-5.08-5.87-7.43-8.68-7.9-2.7-.44-5.48.79-7.43 4.08l-.04.07c-1.17 2-2.02 6.03.15 11.1 2.12 4.92 7.15 10.92 17.77 16.8 21.33-11.49 20-24.06 17.94-27.83l-.04-.08c-1.67-2.94-4.16-4.31-6.68-4.31h-.07z"
      fillRule="nonzero"
    />
  </svg>
)

export const Wellness = props => (
  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M36.4 4.84c3.92.05 7.57 2.25 9.83 6.23l.02.04a1.9 1.9 0 01-3.33 1.84l-.04-.08c-1.93-3.33-4.92-4.61-7.8-4.14-2.94.5-6.23 2.92-8.3 7.97a1.9 1.9 0 01-3.46.13l-.06-.12c-2.57-5.08-5.87-7.43-8.68-7.9-2.72-.44-5.52.8-7.47 4.15l-.06.1c-1.13 2.02-1.92 6 .21 11 2.12 4.92 7.15 10.92 17.77 16.8 4-2.16 7.2-4.36 9.76-6.53l.04-.03a1.9 1.9 0 012.42 2.92l-.14.12A61.07 61.07 0 0125.9 44.7l-.9.46-.88-.47C12.4 38.39 6.38 31.64 3.77 25.55c-2.6-6.09-1.76-11.4.05-14.5l.06-.1C6.5 6.54 10.7 4.32 15.2 5.06c3.58.58 6.98 2.98 9.7 6.95 2.47-4 5.9-6.43 9.57-7.03.61-.1 1.22-.16 1.83-.15h.1z"
      fillRule="nonzero"
    />
    <path
      d="M27.3 22.44l2.2 4.63 4.27-11.74.02-.03a1.9 1.9 0 013.51-.08l.02.04 3.92 9.5 1.4-2.45c.33-.59.96-.95 1.64-.95h3.77a1.9 1.9 0 01-.03 3.8h-2.64l-2.77 4.86a1.9 1.9 0 01-3.4-.22l-3.54-8.56-4.14 11.4a1.9 1.9 0 01-3.5.16l-3.64-7.64H21a1.9 1.9 0 01.03-3.8h4.6c.71 0 1.36.41 1.67 1.05l.02.03z"
      fillRule="nonzero"
    />
  </svg>
)

export const Cafe = props => (
  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M39.45 29.03a5.9 5.9 0 01-5.9 5.82H12.47a5.9 5.9 0 01-5.82-5.9V10.9a1.9 1.9 0 011.9-1.85h29.1c2.04.02 4.4.6 6.32 1.98a8.23 8.23 0 013.48 6.92v.08a8.22 8.22 0 01-3.7 6.86 11.4 11.4 0 01-4.3 1.77v2.37zm4.2-11.17a4.42 4.42 0 00-1.9-3.74 6.75 6.75 0 00-2.3-1.03v9.67a7.38 7.38 0 002.14-1 4.43 4.43 0 002.06-3.81v-.09zM10.45 29a2.1 2.1 0 002.1 2.05H33.6a2.1 2.1 0 002.05-2.1v-16.1h-25.2V29z"
      fillRule="nonzero"
    />
    <path d="M43.55 37.16a4 4 0 01-4 3.79h-33a4 4 0 01-4-4h41v.2z" />
  </svg>
)

export const Park = props => (
  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M37.15 46.1v1a1.9 1.9 0 103.8 0v-1a1.9 1.9 0 10-3.8 0zM32.14 46.1v1a1.9 1.9 0 103.8 0v-1a1.9 1.9 0 10-3.8 0zM27.13 46.1v1a1.9 1.9 0 103.8 0v-1a1.9 1.9 0 10-3.8 0zM17.12 46.1v1a1.9 1.9 0 103.8 0v-1a1.9 1.9 0 10-3.8 0zM12.12 46.1v1a1.9 1.9 0 103.8 0v-1a1.9 1.9 0 10-3.8 0zM7.1 46.1v1a1.9 1.9 0 103.81 0v-1a1.9 1.9 0 10-3.8 0zM22.13 20.84v-7.81a1.9 1.9 0 113.8 0v13.99c3.54-.7 6.7-3.96 6.7-7.98a1.9 1.9 0 013.81 0c0 6.15-5.05 11.09-10.5 11.83V47.1a1.9 1.9 0 11-3.81 0V24.77c-3.27-.64-6.01-2.93-6.01-6.73a1.9 1.9 0 013.8 0c0 1.49.97 2.38 2.2 2.8z" />
    <path
      d="M7.84 29.92A9.42 9.42 0 010 20.66a9.36 9.36 0 019.96-9.37A15.03 15.03 0 0124.5 0c6.92 0 12.86 4.73 14.54 11.29A9.34 9.34 0 0149 20.66c0 5.18-4.23 9.4-9.42 9.4a1.88 1.88 0 110-3.76 5.65 5.65 0 005.65-5.64c0-3.1-2.54-5.63-5.65-5.63-.46 0-.95.08-1.53.24a1.9 1.9 0 01-2.39-1.58A11.26 11.26 0 0024.5 3.76a11.26 11.26 0 00-11.16 9.93 1.88 1.88 0 01-2.39 1.58 5.59 5.59 0 00-1.53-.24 5.65 5.65 0 00-5.65 5.63c0 3.11 2.54 5.64 5.65 5.64a1.88 1.88 0 011.88 2.04l.01.22c0 2.72 3.46 3.44 5.3 1.14a1.8 1.8 0 112.82 2.25 7.06 7.06 0 01-5.76 2.77 5.98 5.98 0 01-5.83-4.8z"
      fillRule="nonzero"
    />
  </svg>
)

export const Play = props => (
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M50 0C22.57 0 0 22.57 0 50s22.57 50 50 50 50-22.57 50-50C99.97 22.58 77.42.03 50 0Zm17.52 53.2L44.44 68.58a3.84 3.84 0 0 1-5.98-3.2V34.63a3.86 3.86 0 0 1 5.98-3.2L67.52 46.8a3.85 3.85 0 0 1 0 6.4Z" />
  </svg>
)

export const getIconFromString = str => {
  switch (str) {
    case 'Sustainability':
      return <Sustainability />
    case 'Bee':
      return <Bee />
    case 'Rain':
      return <Rain />
    case 'Care':
      return <Care />
    case 'Wellness':
      return <Wellness />
    case 'Cafe':
      return <Cafe />
    case 'Park':
      return <Park />
    default:
      return null
  }
}

import Hero from './components/hero'
import Quote from './components/quote'
import Gallery from './components/gallery'
import Cards from './components/cards'
import Content3Columns from './components/content-3-columns'
import InfoSection from './components/info-section'
import FaqModule from './components/faq-module'
import Hotspots from './components/hotspots-container'
import CenteredTextContent from './components/centered-text-content'
import Statistics from './components/statistics'
import HistoryTimeline from './components/history-timeline'
import FactsBox from './components/facts-box'
import ProjectPlan from './components/project-plan'
import Video from './components/video'

export const getSection = section => {
  const { __typename, id } = section

  switch (__typename) {
    case 'ContentfulHeroImage':
      return <Hero key={id} {...section} />
    case 'ContentfulQuote':
      return <Quote key={id} {...section} />
    case 'ContentfulImageGallery':
      return <Gallery key={id} {...section} />
    case 'ContentfulCards':
      return <Cards key={id} {...section} />
    case 'ContentfulContent3Columns':
      return <Content3Columns key={id} {...section} />
    case 'ContentfulInfoSection':
      return <InfoSection key={id} {...section} />
    case 'ContentfulFaqModule':
      return <FaqModule key={id} {...section} />
    case 'ContentfulHotspotContainer':
      return <Hotspots key={id} {...section} />
    case 'ContentfulCenteredText':
      return <CenteredTextContent key={id} {...section} />
    case 'ContentfulStatistics':
      return <Statistics key={id} {...section} />
    case 'ContentfulHistoryTimeline':
      return <HistoryTimeline key={id} {...section} />
    case 'ContentfulFactModule':
      return <FactsBox key={id} {...section} />
    case 'ContentfulProjectPlan':
      return <ProjectPlan key={id} {...section} />
    case 'ContentfulVideo':
      return <Video key={id} {...section} />
    default:
      console.warn(`Missing page section: ${__typename}`)
      return null
  }
}

import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SectionTop from '../components/section-top'
import ContentWrapper from '../components/content-wrapper'
import { getSection } from '../page-helper'

const PageIntro = styled(ContentWrapper)`
  margin-top: 10rem;
  margin-bottom: 5rem;

  @media (min-width: 48em) {
    margin-top: 20rem;
    margin-bottom: 12rem;
  }
`

const Page = ({
  data: {
    contentfulPage: { title, intro, pageSections, metaDescription, media },
  },
}) => {
  const showIntro = !pageSections || pageSections[0].__typename !== 'ContentfulHeroImage'

  return (
    <Layout showPattern={showIntro}>
      <SEO title={title} description={metaDescription} image={media?.file.url} />

      {showIntro && (
        <PageIntro>
          <SectionTop title={title} text={intro} as="h1" />
        </PageIntro>
      )}
      {pageSections && pageSections.map((section, i) => getSection(section, i))}
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object,
}

export default Page

export const pageQuery = graphql`
  query DefaultPage($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      intro {
        raw
      }
      metaDescription
      media {
        file {
          url
        }
      }
      pageSections {
        __typename
        ... on Node {
          ... on ContentfulHeroImage {
            id
            title
            media {
              fluid(quality: 50) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulHotspotContainer {
            id
            hotspots {
              title
              media {
                contentful_id
                fluid(quality: 50) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              data {
                internal {
                  content
                }
              }
            }
            backgroundColor
          }
          ... on ContentfulImageGallery {
            id
            images: media {
              fluid(quality: 50) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            backgroundColor
            removeTopPadding
          }
          ... on ContentfulCards {
            id
            cards {
              id
              title
              text {
                raw
              }
              media {
                fluid(quality: 50) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              mediaHeight
              fadeMedia
              rightAdjustMedia
              backgroundColor
            }
            backgroundColor
          }
          ... on ContentfulContent3Columns {
            id
            title
            text {
              raw
            }
            content {
              ... on Node {
                ... on ContentfulContentBlock {
                  id
                  title
                  text {
                    raw
                  }
                  callToActionText
                  callToActionLink
                }
                ... on ContentfulContentBlockWithIcon {
                  id
                  title
                  text {
                    raw
                  }
                  icon
                }
              }
            }
            backgroundColor
            whiteColumns
          }
          ... on ContentfulInfoSection {
            id
            title
            text1 {
              raw
            }
            text2 {
              raw
            }
            images: media {
              fluid(quality: 50) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            mediaLayout
            callToActionText
            callToActionLink
            backgroundColor
          }
          ... on ContentfulFaqModule {
            id
            categories {
              category
              faqs {
                question
                answer {
                  answer
                }
                #media {
                #  fluid(quality: 50) {
                #    ...GatsbyContentfulFluid_withWebp
                #  }
                #}
              }
            }
            text {
              raw
            }
          }
          ... on ContentfulCenteredText {
            id
            title
            text {
              raw
            }
            backgroundColor
            showPattern
          }
          ... on ContentfulStatistics {
            id
            title
            text {
              raw
            }
            statisticsTitle
            statisticsText {
              raw
            }
            chartLayout
            label1
            label2
            comparisonSize
            backgroundColor
          }
          ... on ContentfulHistoryTimeline {
            id
            title
            events {
              title
              text {
                raw
              }
              year
              media {
                fluid(quality: 50) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
          ... on ContentfulFactModule {
            id
            title
            text {
              raw
            }
            backgroundColor
          }
          ... on ContentfulProjectPlan {
            id
            data {
              internal {
                content
              }
            }
          }
          ... on ContentfulVideo {
            id
            video
            videoThumbnail {
              fluid(quality: 50) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

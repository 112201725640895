import PropTypes from 'prop-types'
import styled from 'styled-components'
import SectionTop from './section-top'
import ContentWrapper from './content-wrapper'
import Pattern from './pattern'
import { getColorVariable } from '../utils'

const Container = styled.section`
  margin-top: -2rem;
  border-radius: 2rem 2rem 0 0;

  ${({ background }) => `background: var(--color-${background});`}

  ${({ background }) =>
    background === 'primary-1' &&
    `
    color: white;

    h2, h3 {
      color: inherit;
    }

  `}
`

const Inner = styled.div`
  position: relative;
  overflow: hidden;
  padding: 5rem 0;

  @media (min-width: 48em) {
    padding: 8rem 0;
  }

  @media (min-width: 62.5em) {
    padding: 11rem 0 9rem;
  }

  ${({ extraPadding }) =>
    extraPadding &&
    `
    padding: 10rem 0 6rem;

    @media (min-width: 48em) {
      padding: 12rem 0 8rem;
    }

    @media (min-width: 62.5em) {
      padding: 15rem 0 13rem;
    }
  `}
`

const StyledPattern = styled(Pattern)`
  z-index: 1;
`

const CenteredTextContent = ({ title, text, backgroundColor, showPattern }) => {
  return (
    <Container background={getColorVariable(backgroundColor || 'White')}>
      <Inner extraPadding={showPattern}>
        <ContentWrapper>
          <SectionTop title={title} text={text} />
          {showPattern && <StyledPattern />}
        </ContentWrapper>
      </Inner>
    </Container>
  )
}

CenteredTextContent.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object,
  backgroundColor: PropTypes.string,
  showPattern: PropTypes.bool,
}

export default CenteredTextContent

import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import useResizeAware from 'react-resize-aware'

const Container = styled.div`
  border-radius: 0 0 2rem 2rem;
  overflow: hidden;
  counter-reset: panels;

  @media (min-width: 62.5em) {
    display: none;
  }
`

const Div = styled(animated.div)`
  overflow: hidden;

  > div {
    position: relative;
    padding: 0 1.6rem 3.2rem;

    @media (min-width: 48em) {
      padding: 0 2.5rem 3.2rem 3.2rem;
    }
  }
`

const PanelContainer = styled.div`
  background: var(--color-primary-3);
  margin-bottom: 0.3rem;
  transition: background 300ms;
  counter-increment: panels;

  ${({ isOpen }) =>
    isOpen &&
    `
    background: white;
  `}
`

const PanelContent = styled.div`
  max-width: 50rem;
`

const Heading = styled.h3`
  font-size: inherit;
  font-weight: 400;
  padding: 1.7rem 1.6rem;
  cursor: pointer;
  position: relative;

  &::before {
    content: counter(panels) '. ';
  }

  @media (min-width: 48em) {
    padding: 1.7rem 3.2rem;
  }
`

const Icon = styled.div`
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;
  right: 1.6rem;
  top: 2rem;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 1.7rem;
    height: 0.1rem;
    background: var(--color-black);
    position: absolute;
    left: 0;
    top: 50%;
    transition: transform 300ms;
  }

  &::after {
    transform: rotate(90deg);
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    &::before {
      transform: rotate(135deg);
    }
    &::after {
      transform: rotate(225deg);
    }
  `}

  @media (min-width: 48em) {
    right: 2.9rem;
  }
`

const Panel = ({ isOpen, children, ...props }) => {
  const [resizeListener, sizes] = useResizeAware()
  const spring = useSpring({ height: isOpen && sizes.height ? sizes.height : 0 })

  return (
    <Div {...props} style={spring}>
      <div>
        {resizeListener}
        {children}
      </div>
    </Div>
  )
}

const HotspotsAccordion = ({ texts }) => {
  const [openTabs, setOpenTabs] = useState([])

  const onClick = tab => {
    const newOpenTabs = openTabs.includes(tab)
      ? openTabs.filter(t => t !== tab)
      : [...openTabs, tab]

    setOpenTabs(newOpenTabs)
  }

  useEffect(() => {
    setOpenTabs([])
  }, [texts])

  return (
    <Container>
      {texts &&
        texts.map(({ title, text, x, y }, i) => (
          <PanelContainer key={`${x}${y}`} isOpen={openTabs.includes(i)}>
            <Heading onClick={() => onClick(i)}>
              {title} <Icon isOpen={openTabs.includes(i)} />
            </Heading>

            <Panel isOpen={openTabs.includes(i)}>
              <PanelContent>{text}</PanelContent>
            </Panel>
          </PanelContainer>
        ))}
    </Container>
  )
}

Panel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
}

HotspotsAccordion.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.object),
}

export default HotspotsAccordion

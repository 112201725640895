import PropTypes from 'prop-types'
import { useState } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { fadeIn } from '../styles/animations'
import { Arrow } from './icons'

const Container = styled.div`
  padding-top: 65%;
  position: relative;
  counter-reset: hotspots;
  animation: ${fadeIn} 300ms forwards;
`

const Button = styled.button`
  position: absolute;
  z-index: 10;
  width: 2.4rem;
  height: 2.4rem;
  margin-top: -1.2rem;
  background: rgba(255, 255, 255, 0.6);
  border: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transition: opacity 300ms, background 300ms;
  font-size: 1.4rem;
  outline: 0;

  &:hover {
    background: white;
  }

  &:focus {
    box-shadow: inset 0 0 0 2px white;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 200%;
    height: 200%;
    position: absolute;
  }

  > svg {
    width: 1rem;
    height: 1rem;
  }

  @media (min-width: 48em) {
    width: 4rem;
    height: 4rem;
    margin-top: -2rem;

    &::before,
    &::after {
      display: none;
    }

    > svg {
      width: 1.3rem;
      height: 1.3rem;
    }
  }
`

const Previous = styled(Button)`
  left: 0.8rem;
  transform: rotate(180deg);

  @media (min-width: 48em) {
    left: 2.4rem;
  }
`

const Next = styled(Button)`
  right: 0.8rem;

  @media (min-width: 48em) {
    right: 2.4rem;
  }
`

const Image = styled(Img)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: opacity 500ms;
  overflow: hidden;
  border-radius: 2rem 2rem 0 0;
`

const Text = styled.div`
  width: 25rem;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms;
  color: var(--color-black);
`

const Hotspot = styled.div`
  position: absolute;
  border-radius: 2rem;
  background: var(--color-secondary-1);
  width: 2.4rem;
  height: 2.4rem;
  margin: -1.2rem 0 0 -1.2rem;
  z-index: 10;
  transition: top 500ms, left 500ms, z-index 0ms 150ms;
  will-change: top, left;
  pointer-events: none;
  counter-increment: hotspots;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    font-size: 1.2rem;
    content: counter(hotspots);
    color: white;
  }

  &:hover {
    z-index: 20;
    transition-delay: 0ms, 0ms, 0ms;

    ${Text} {
      opacity: 1;
      transition-delay: 150ms;
    }
  }

  @media (min-width: 48em) {
    width: 4rem;
    height: 4rem;
    margin: -2rem 0 0 -2rem;

    &::after {
      font-size: 1.6rem;
    }
  }

  @media (min-width: 62.5em) {
    pointer-events: all;

    &::after {
      display: none;
    }
  }
`

const HotspotContent = styled.div`
  height: 4rem;
  background: white;
  border-radius: 2rem;
  position: absolute;
  left: 100%;
  top: 0;
  font-size: 1.6rem;
  padding: 0 1.6rem;
  transition: width 300ms, height 300ms, opacity 300ms;
  display: none;

  ${({ reverse }) => reverse && `left: auto; right: 100%;`}

  @media (min-width: 62.5em) {
    display: block;
  }
`

const Heading = styled.h3`
  white-space: nowrap;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 4rem;
`

const Hotspots = ({ data: { media, hotspots }, onMediaIndexChange }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentHovered, setCurrentHovered] = useState()

  media.map(({ fluid }) => {
    fluid.sizes = '(min-width: 87em) 82em, (min-width: 48em) 50vw, 100vw'
  })

  const onMouseEnter = ({ currentTarget }) => {
    const el = currentTarget.children[0]
    const { offsetWidth, offsetHeight } = el.lastChild

    setCurrentHovered(Array.from(currentTarget.parentNode.children).indexOf(currentTarget))

    if (!el.savedWidth) {
      el.savedWidth = el.offsetWidth
    }

    el.style.width = `${el.savedWidth}px`

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        el.style.width = `${offsetWidth + 50}px`
        el.style.height = `${offsetHeight + 50}px`
      })
    })
  }

  const onMouseLeave = ({ currentTarget }) => {
    const el = currentTarget.children[0]
    setCurrentHovered()

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        el.style.width = `${el.savedWidth}px`
        el.style.height = ``
      })
    })
  }

  return (
    <Container>
      {media.length > 1 && (
        <>
          <Previous
            onClick={() => {
              const index = Math.abs((currentIndex - 1) % media.length)
              setCurrentIndex(index)
              onMediaIndexChange(index)
            }}
          >
            <Arrow />
          </Previous>
          <Next
            onClick={() => {
              const index = Math.abs((currentIndex + 1) % media.length)
              setCurrentIndex(index)
              onMediaIndexChange(index)
            }}
          >
            <Arrow />
          </Next>
        </>
      )}

      {media.map(({ contentful_id, fluid }, i) => (
        <Image fluid={fluid} key={contentful_id} style={{ opacity: currentIndex === i ? 1 : 0 }} />
      ))}

      <div>
        {hotspots[media[currentIndex].contentful_id] &&
          hotspots[media[currentIndex].contentful_id].map((hotspot, i) => (
            <Hotspot
              key={i}
              style={{ left: `${hotspot.x * 100}%`, top: `${hotspot.y * 100}%` }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <HotspotContent
                style={{
                  opacity: typeof currentHovered === 'undefined' || currentHovered === i ? 1 : 0,
                }}
                reverse={hotspot.x > 0.75}
              >
                <Heading>{hotspot.title}</Heading>
                <Text>{hotspot.text}</Text>
              </HotspotContent>
            </Hotspot>
          ))}
      </div>
    </Container>
  )
}

Hotspots.propTypes = {
  data: PropTypes.object,
  onMediaIndexChange: PropTypes.func,
}

export default Hotspots

import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import ContentWrapper from '../components/content-wrapper'

const Container = styled(ContentWrapper)`
  overflow: auto;
`

const Timeline = styled.div`
  position: relative;
  margin: 0 0 10rem;
  color: var(--color-black);
  min-width: 92rem;
  padding: 8rem 0;
`

const Top = styled.div``

const Middle = styled.div`
  position: relative;
  padding-top: 14.25%;
`

const Marker = styled.div`
  width: 0.1rem;
  position: absolute;
  bottom: 50%;
  white-space: nowrap;
  padding: 0.5rem 0 0 1.5rem;
  border-left: 1px solid rgba(176, 158, 155, 0.5);
  transition: height 1200ms, opacity 700ms;

  .no-js & {
    height: 50% !important;
    opacity: 1 !important;
  }
`

const MarkerBottom = styled(Marker)`
  /*
  top: 50%;
  bottom: 4rem;
  align-items: flex-end;
  */

  bottom: 55%;
  z-index: 10;
  display: flex;
  mix-blend-mode: difference;

  .no-js & {
    height: calc(50% - 4rem) !important;
    opacity: 1 !important;
  }
`

const MarkerNow = styled.div`
  width: 0.1rem;
  position: absolute;
  white-space: nowrap;
  padding: 0.5rem 0 0 1.5rem;
  border-left: 1px solid rgba(176, 158, 155, 0.5);
  top: 55%;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  z-index: 9;
  transition: height 1200ms, opacity 700ms;
  mix-blend-mode: difference;
`

const Entry = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 999rem;
  background: var(--color-primary-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2.4rem;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 2rem;
  transform-origin: 0% 50%;
  transition: opacity 700ms 0ms, transform 800ms 0ms;

  .no-js & {
    opacity: 1 !important;
    transform: translate(0, 0) !important;
  }
`

const Arrow = styled.svg`
  width: 1.8rem;
  height: 1.8rem;
`

const getColor = index => {
  const colors = {
    0: '--color-white',
    1: '--color-primary-1',
    2: '--color-secondary-2',
    3: '--color-primary-2',
    4: '--color-secondary-1',
  }

  return colors[index]
}

const getTextColor = index => {
  if (index === 1 || index === 4) {
    return 'white'
  }
}

const ProjectPlan = ({ data }) => {
  data = data ? JSON.parse(data.internal.content) : {}

  if (!data.entries) {
    return null
  }

  const [containerRef, inView] = useInView({
    rootMargin: '0px 0px -70px 0px',
    triggerOnce: true,
  })

  const [isDone, setIsDone] = useState(false)
  const [now, setNow] = useState()
  const [activeIndex, setActiveIndex] = useState(-1)

  useEffect(() => {
    const startDate = new Date(data.startYear)
    const endDate = new Date(startDate)

    endDate.setFullYear(startDate.getFullYear() + 14)
    const totalTime = endDate.getTime() - startDate.getTime()
    const currentDate = new Date()

    setNow((currentDate.getTime() - startDate.getTime()) / totalTime)
  }, [])

  useEffect(() => {
    if (!inView) {
      return
    }

    let timeout = setTimeout(() => {
      if (activeIndex < data.entries.length - 1) {
        setActiveIndex(activeIndex + 1)
      } else {
        setIsDone(true)
      }
    }, 500)

    return () => clearTimeout(timeout)
  }, [inView, activeIndex])

  const current = data.entries
    .slice(data.entries.length - activeIndex - 1)
    .reduce((acc, { width }) => acc + parseInt(width, 10), 0)

  return (
    <Container>
      <Timeline ref={containerRef}>
        <Top>
          {data.entries.map(
            ({ pos, text }, i) =>
              false && (
                <Marker
                  key={pos}
                  style={{
                    left: `${(pos / 14) * 100}%`,
                    height: `${activeIndex >= i ? 45 : 35}%`,
                    opacity: activeIndex >= i ? 1 : 0,
                  }}
                >
                  {text}
                </Marker>
              )
          )}

          {data.markers.map(({ pos, text }) => {
            const width = activeIndex >= 0 ? data.entries[activeIndex].width : 0

            return (
              <MarkerBottom
                key={pos}
                style={{
                  left: `${(pos / 28) * 100}%`,
                  height: pos < (current + width) * 2 ? '45%' : '0%',
                  opacity: pos < (current + width) * 2 ? 1 : 0,
                }}
                dangerouslySetInnerHTML={{ __html: pos >= 26 ? text.replace(' ', '<br />') : text }}
              />
            )
          })}
          {now && (
            <MarkerNow
              style={{
                left: `${now * 100}%`,
                height: `${isDone ? 45 : 0}%`,
                opacity: isDone ? 1 : 0,
              }}
            >
              Här är vi
            </MarkerNow>
          )}
        </Top>

        <Middle>
          {data.entries.map(({ pos, width, color }, i) => {
            const date = new Date(data.startYear)
            date.setFullYear(date.getFullYear() + pos)

            return (
              <Entry
                key={pos}
                style={{
                  left: `${(pos / 14) * 100}%`,
                  width: `${(width / 14) * 100}%`,
                  background: `var(${getColor(color)})`,
                  color: getTextColor(color),
                  opacity: activeIndex >= i ? 1 : 0,
                  transform: `translate(-${activeIndex >= i ? 0 : '1.5rem'}, 0)`,
                }}
              >
                {date.getFullYear()}

                <Arrow viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                  <path d="M.75 9.77A.76.76 0 010 9c0-.43.34-.77.76-.77H15.1L8.3 1.32a.78.78 0 010-1.1c.3-.3.78-.3 1.08 0L18 9l-8.62 8.77c-.3.3-.78.3-1.07 0a.78.78 0 010-1.09l6.79-6.9H.76z" />
                </Arrow>
              </Entry>
            )
          })}
        </Middle>
      </Timeline>
    </Container>
  )
}

ProjectPlan.propTypes = {
  data: PropTypes.object,
}

export default ProjectPlan

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import ContentWrapper from './content-wrapper'
import { getColorVariable } from '../utils'

const Container = styled.section`
  margin-top: -2rem;
  padding: 4rem 0;
  border-radius: 2rem 2rem 0 0;
  ${({ background }) => `background: var(--color-${background});`}

  @media (min-width: 48em) {
    padding: 4rem 0 6rem;
  }

  @media (min-width: 62.5em) {
    padding: 6rem 0 8rem;
  }
`

const Content = styled.div`
  background: var(--color-primary-1);
  color: var(--color-white);
  border-radius: 2rem;
  padding: 3rem 2rem;
  position: relative;
  overflow: hidden;
  display: flex;
  min-height: 22rem;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 62.5em) {
    padding: 4rem 4.8rem;
    min-height: 30rem;
  }

  @media (min-width: 75em) {
    padding: 4rem 6rem 6rem;
    min-height: 40rem;
  }
`

const Heading = styled.h2`
  color: inherit;
  font-size: 1.9rem;
  max-width: 30rem;
  margin: 0 0 2rem;
  line-height: 1.2;

  @media (min-width: 62.5%) {
    font-size: 2.4rem;
  }
`

const Text = styled.div`
  font-size: 1.8rem;
  line-height: 1.125;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    &:last-child {
      margin: 0;
    }
  }

  li p {
    margin: 0;
  }

  li {
    position: relative;
    padding-left: 1em;

    &::before {
      content: '';
      width: 0.75em;
      height: 0.75em;
      position: absolute;
      left: 0;
      top: 0.2em;
      background: currentColor;
      border-radius: 50%;
    }

    + li {
      margin-top: 0.5rem;
    }
  }

  p:empty {
    display: none;
  }

  @media (min-width: 48em) {
    font-size: 2rem;
  }

  @media (min-width: 75em) {
    font-size: 2.4rem;

    li + li {
      margin: 0;
    }
  }
`

const Pattern = styled.div`
  opacity: 0.05;
  position: absolute;
  left: -50%;
  top: -30%;
  width: 200%;
  pointer-events: none;

  &::before {
    content: '';
    display: block;
    padding-top: 54.7274%;
  }

  > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 48em) {
    width: 110%;
    left: 22%;
  }
`

const FactsBox = ({ title, text, backgroundColor }) => {
  return (
    <Container background={getColorVariable(backgroundColor || 'Rose white')}>
      <ContentWrapper>
        <Content>
          <Heading>{title}</Heading>
          <Text>{renderRichText(text)}</Text>
          <Pattern>
            <svg viewBox="0 0 1449 793" xmlns="http://www.w3.org/2000/svg">
              <path d="M231.49 346.5a42.16 42.16 0 00-59.6 0L12.34 506.04a42.16 42.16 0 000 59.6 42.16 42.16 0 0059.6 0l159.55-159.56a42.16 42.16 0 000-59.6zM1160.02 386.19a42.16 42.16 0 000-59.6l-143.54-143.55a42.17 42.17 0 00-59.6 0 42.16 42.16 0 000 59.6l143.54 143.55a42.16 42.16 0 0059.6 0z" />
              <path d="M1435.74 780.59a42.16 42.16 0 000-59.6l-342.96-342.97a42.16 42.16 0 00-59.6 0 42.16 42.16 0 000 59.6l342.96 342.97a42.17 42.17 0 0059.6 0zM952.46 127.49a42.16 42.16 0 00-59.6 0L534.18 486.17a42.16 42.16 0 000 59.6 42.16 42.16 0 0059.6 0l358.68-358.68a42.16 42.16 0 000-59.6z" />
              <path d="M233.84 297.06a42.16 42.16 0 000 59.6l236.4 236.4a42.16 42.16 0 0059.6 0 42.16 42.16 0 000-59.6l-236.4-236.4a42.16 42.16 0 00-59.6 0z" />
              <path d="M63.2 12.34a42.16 42.16 0 000 59.6l236.4 236.4a42.16 42.16 0 0059.6 0 42.16 42.16 0 000-59.6L122.8 12.33a42.16 42.16 0 00-59.6 0z" />
            </svg>
          </Pattern>
        </Content>
      </ContentWrapper>
    </Container>
  )
}

FactsBox.propTypes = {
  title: PropTypes.string,
  text: PropTypes.object,
  backgroundColor: PropTypes.string,
}

export default FactsBox

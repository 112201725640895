import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import Img from 'gatsby-image'
import { useSpring, animated } from 'react-spring'
import useResizeAware from 'react-resize-aware'
import styled from 'styled-components'
import Fuse from 'fuse.js'
import ContentWrapper from './content-wrapper'
import ContactForm from './contact-form'
import ActiveInView from './active-in-view'
import { Search as SearchIcon } from './icons'
import { fadeIn } from '../styles/animations'

const TopHeading = styled.h2`
  font-size: var(--typography-3);
  margin: 3rem 0 3rem;

  .no-js & {
    display: none;
  }
`

const Heading = styled.h2`
  font-size: var(--typography-3);
  margin: 3rem 0 3rem;
`

const Filter = styled(ActiveInView)`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 0 2.4rem;

  .no-js & {
    display: none;
  }

  @media (min-width: 48em) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.4rem;
  }
`

const Category = styled.button`
  appearance: none;
  border: 0;
  padding: 0;
  font-size: var(--typography-body);
  background: var(--color-primary-3);
  border-radius: 2rem;
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 13rem;
  transform: scale(0.9);
  opacity: 0;
  transition-property: transform, opacity, background-color;
  transition-duration: 250ms;

  &:hover {
    background: var(--color-secondary-2);
  }

  ${({ active }) =>
    active &&
    `
    background: white;

    &:hover {
          background: white;

    }
  `}

  .is-active & {
    transform: none;
    opacity: 1;
  }

  @media (min-width: 48em) {
    padding: 2rem;
  }
`

const Answers = styled.div`
  padding: 0 0 5rem;
  display: none;

  .no-js & {
    display: block;
  }

  @media (min-width: 48em) {
    padding: 0 0 10rem;
  }
`

const Question = styled.div`
  animation: ${fadeIn} 150ms forwards;
  background: white;
  border-radius: 2rem;
  margin: 0 0 0.4rem;
`

const QHeading = styled.h3`
  font-size: inherit;
  font-weight: 400;
  padding: 3.2rem;
  cursor: pointer;
  position: relative;
`

const Answer = styled(animated.div)`
  overflow: hidden;

  > div {
    position: relative;
    padding: 0 3.2rem 3.2rem;
  }

  .no-js & {
    height: auto !important;
  }
`

const Icon = styled.div`
  width: 4.6rem;
  height: 4.6rem;
  position: absolute;
  right: 2rem;
  top: 2rem;
  background: var(--color-secondary-3);
  border-radius: 50%;

  .no-js & {
    display: none;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 2.2rem;
    height: 0.1rem;
    background: var(--color-black);
    position: absolute;
    left: 1.2rem;
    top: 50%;
    transition: transform 300ms;
  }

  &::after {
    transform: rotate(90deg);
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    &::before {
      transform: rotate(135deg);
    }
    &::after {
      transform: rotate(225deg);
    }
  `}
`

const Search = styled.div`
  position: relative;
  margin: 0 0 6.5rem;

  .no-js & {
    display: none;
  }

  > input {
    appearance: none;
    border: 0;
    background: var(--color-primary-3);
    height: 6.4rem;
    width: 100%;
    border-radius: 10rem;
    padding: 0 4rem 0 8rem;
  }

  > svg {
    position: absolute;
    left: 3.2rem;
    top: 50%;
    margin-top: -0.7rem;
  }
`
const Image = styled(Img)`
  border-radius: 2rem;
`

const AnswerContent = styled.div`
  display: grid;
  grid-gap: 2.4rem;

  @media (min-width: 48em) {
    grid-template-columns: 1fr 1fr;
  }
`

const Panel = ({ isOpen, children, ...props }) => {
  const [resizeListener, sizes] = useResizeAware()
  const spring = useSpring({ height: isOpen ? sizes.height : 0 })

  return (
    <Answer {...props} style={spring}>
      <div>
        {resizeListener}
        {children}
      </div>
    </Answer>
  )
}

const FaqModule = ({ categories, text }) => {
  const [currentCategory, setCategory] = useState()
  const [openAnswers, setOpenAnswers] = useState([])
  const [query, setQuery] = useState('')
  const [searchResults, setSearchResult] = useState()
  const fuse = useRef()
  const answersRef = useRef()
  const list = categories.map(({ faqs }) => faqs).flat()
  const unique = []

  list.map(q => (unique.find(({ question }) => question === q.question) ? null : unique.push(q)))

  unique.map(({ media }) => {
    if (media?.fluid) {
      media.fluid.sizes = '(min-width: 87em) 38em, (min-width: 48em) 50vw,80vw'
    }
  })

  useEffect(() => {
    fuse.current = new Fuse(unique, {
      includeScore: true,
      minMatchCharLength: 3,
      ignoreLocation: true,
      keys: ['question', 'answer.answer'],
    })

    answersRef.current.style.display = 'block'
  }, [])

  const doSearch = ({ target: { value } }) => {
    setQuery(value)
    setCategory(null)

    if (value.length > 2) {
      setSearchResult(fuse.current.search(value).filter(({ score }) => score <= 0.5))
    } else {
      setSearchResult()
    }
  }

  let faqs

  if (searchResults) {
    faqs = searchResults.map(({ item }) => item)
  } else {
    if (typeof window === 'undefined' || currentCategory === 'all') {
      faqs = unique
    } else {
      const [filteredCategory] = categories.filter(({ category }) => category === currentCategory)

      if (filteredCategory) {
        faqs = filteredCategory.faqs
      }
    }
  }

  const onClickCategory = category => {
    const newCategory = currentCategory === category ? null : category

    setQuery('')
    setSearchResult()
    setCategory(newCategory)
    setOpenAnswers([])

    if (newCategory) {
      window.scroll({
        top: answersRef.current.offsetTop - 150,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  const onQuestionClick = question => {
    const newOpenAnswers = openAnswers.includes(question)
      ? openAnswers.filter(q => q !== question)
      : [...openAnswers, question]

    setOpenAnswers(newOpenAnswers)
  }

  return (
    <ContentWrapper>
      <TopHeading>Kategori</TopHeading>

      <Filter>
        {categories.map(({ category }, i) => (
          <Category
            key={category}
            onClick={() => onClickCategory(category)}
            active={currentCategory === category}
            style={{ transitionDelay: `${(i + 1) * 50}ms, ${(i + 1) * 50}ms, 0ms` }}
          >
            {category}
          </Category>
        ))}
        <Category
          onClick={() => onClickCategory('all')}
          active={currentCategory === 'all'}
          style={{
            transitionDelay: `${categories.length * 50}ms, ${categories.length * 50}ms, 0ms`,
          }}
        >
          Alla frågor och svar
        </Category>
      </Filter>

      <Search>
        <input type="text" placeholder="Sök i alla frågor" value={query} onChange={doSearch} />
        <SearchIcon />
      </Search>

      <Answers ref={answersRef}>
        {faqs && (
          <>
            <Heading>Svar</Heading>

            {faqs.length === 0 && <div>Inga svar hittades.</div>}

            {faqs.map(({ question, answer: { answer }, media }) => (
              <Question key={question}>
                <QHeading onClick={() => onQuestionClick(question)}>
                  {question} <Icon isOpen={openAnswers.includes(question)} />
                </QHeading>

                <Panel isOpen={openAnswers.includes(question)}>
                  <AnswerContent>
                    <div dangerouslySetInnerHTML={{ __html: answer }} />
                    {media && <Image fluid={media.fluid} />}
                  </AnswerContent>
                </Panel>
              </Question>
            ))}
          </>
        )}
      </Answers>

      <ContactForm text={text} />
    </ContentWrapper>
  )
}

Panel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
}

FaqModule.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  text: PropTypes.object,
}

export default FaqModule

import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Button from './button'

const Container = styled.div`
  display: grid;
  grid-gap: 2rem;
  margin: 0 0 6rem;

  .no-js & {
    display: none;
  }

  @media (min-width: 48em) {
    grid-template-columns: 1fr 1fr;
    margin: 0 0 12rem;
  }
`

const Text = styled.div`
  max-width: 39rem;

  h2 {
    font-size: var(--typography-3);
    line-height: 1.1667;
    margin: 0 0 0.6em;
  }
`

const InputText = styled.input`
  appearance: none;
  background: white;
  border-radius: 2rem;
  border: 0;
  width: 100%;
  margin: 0 0 0.4rem;
  padding: 2.9rem 3.2rem;
  font-size: inherit;
  color: var(--color-body);
`

const Textarea = styled(InputText)`
  min-height: 17.6rem;
  margin-bottom: 2.4rem;
`

const Error = styled.p`
  margin: 2rem 0 0;
`

const ContactForm = ({ text }) => {
  const formRef = useRef()
  const [data, setData] = useState({
    email: '',
    message: '',
  })
  const [showError, setShowError] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [hasSent, setHasSent] = useState(false)

  const send = async () => {
    if (isSending) {
      return
    }

    const { current: form } = formRef
    const formData = new FormData(form)

    setIsSending(true)
    setShowError(false)

    try {
      const res = await fetch('https://api.staticforms.xyz/submit', {
        method: 'POST',
        body: JSON.stringify(Object.fromEntries(formData)),
        headers: { 'Content-Type': 'application/json' },
      })

      const json = await res.json()

      if (!json.success) {
        throw json.message
      }

      setHasSent(true)
    } catch (error) {
      setShowError(true)
    }

    setIsSending(false)
  }

  const onChange = ({ target: { name, value } }) => {
    setData({
      ...data,
      [name]: value,
    })
  }

  const onSubmit = e => {
    e.preventDefault()
    send()
  }

  const richTextOptions = {
    renderNode: {
      hyperlink: ({ data: { uri } }, children) =>
        uri.indexOf('http') >= 0 ? (
          <a href={uri}>{children.join(' ')}</a>
        ) : (
          <Link to={uri}>{children.join(' ')}</Link>
        ),
    },
  }

  return (
    <Container>
      <Text>{text && <>{renderRichText(text, richTextOptions)}</>}</Text>
      {hasSent ? (
        <p>Tack för din fråga! Vi kommer höra av oss inom kort.</p>
      ) : (
        <form
          ref={formRef}
          action="https://api.staticforms.xyz/submit"
          method="post"
          onSubmit={onSubmit}
        >
          <div>
            <InputText
              type="email"
              name="email"
              value={data.email}
              required
              placeholder="Din e-postadress"
              onChange={onChange}
            />
            <Textarea
              as="textarea"
              name="message"
              value={data.message}
              required
              placeholder="Skriv din fråga här"
              onChange={onChange}
            />

            <input type="hidden" name="replyTo" value="@" />
            <input type="hidden" name="accessKey" value="565502ca-7ebf-43b1-8a1a-34d6bd988407" />
            <input type="hidden" name="subject" value="Ny fråga" />
            <input type="text" name="honeypot" style={{ display: 'none' }} />

            <Button disabled={isSending}>Skicka in</Button>

            {showError && <Error>Någonting gick fel. Försök igen senare.</Error>}
          </div>
        </form>
      )}
    </Container>
  )
}

ContactForm.propTypes = {
  text: PropTypes.object,
}

export default ContactForm

import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

const ActiveInView = ({ is, ...props }) => {
  const [containerRef, inView, entry] = useInView({
    rootMargin: '0px 0px -70px 0px',
    triggerOnce: true,
  })

  const Component = is || 'div'

  useEffect(() => {
    if (entry && entry.isIntersecting) {
      entry.target.classList.add('is-active')
    }
  }, [inView])

  return <Component ref={containerRef} {...props} />
}

ActiveInView.propTypes = {
  is: PropTypes.string,
}

export default ActiveInView

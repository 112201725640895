import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import ContentWrapper from './content-wrapper'
import Hotspots from './hotspots'
import HotspotsAccordion from './hotspots-accordion'
import { getColorVariable } from '../utils'
import { Arrow } from './icons'

const Container = styled.section`
  border-radius: 2rem;
  margin-top: -2rem;
  padding: 2rem 0 4rem;

  ${({ background }) => `background: var(--color-${background});`}

  @media (min-width: 62.5em) {
    padding: 11rem 0 13rem;
  }
`

const Buttons = styled.div`
  display: none;

  @media (min-width: 62.5em) {
    height: 12.6rem;
    display: flex;
    background: var(--color-primary-3);
    border-radius: 0 0 2rem 2rem;
    overflow: hidden;
  }
`

const Button = styled.button`
  appearance: none;
  background: transparent;
  border: 0;
  padding: 2rem;
  color: var(--color-black);
  width: ${100 / 6}%;
  border-right: 0.3rem solid var(--color-secondary-3);
  outline: 0;
  position: relative;

  &:hover {
    background: #f0eae8;
  }

  &:focus {
    background: #e1dad7;
  }

  &.is-active {
    background: var(--color-white);
    border-right-color: var(--color-white);

    &::after {
      content: '';
      display: block;
      height: 100%;
      width: 0.3rem;
      right: 100%;
      top: 0;
      position: absolute;
      background: white;
      z-index: 1;
    }
  }

  &:nth-child(6) {
    border-right: 0;
  }
`

const SelectHolder = styled.div`
  height: 5.6rem;
  position: relative;
  background: var(--color-white);
  margin: 0 0 0.3rem;

  > svg {
    position: absolute;
    right: 2rem;
    top: 50%;
    margin-top: -0.6rem;
    transform: rotate(90deg);
    pointer-events: none;

    @media (min-width: 48em) {
      right: 3.2rem;
    }
  }

  @media (min-width: 62.5em) {
    display: none;
  }
`

const Select = styled.select`
  appearance: none;
  border: 0;
  padding: 0;
  width: 100%;
  background: none !important;
  outline: 0;
  height: 100%;
  cursor: pointer;
  padding: 0 1.6rem;
  color: var(--color-black);

  @media (min-width: 48em) {
    padding: 0 3.2rem;
  }
`

const HotspotsContainer = ({ hotspots, backgroundColor }) => {
  hotspots.map(hotspot => {
    hotspot.hotspots = hotspot.data ? JSON.parse(hotspot.data.internal.content) : {}

    return hotspot
  })

  const [index, setIndex] = useState(0)
  const [mediaIndex, setMediaIndex] = useState(0)
  const [currentHotspots, setCurrentHotspots] = useState(hotspots[0].hotspots[hotspots[0].media[0].contentful_id])

  useEffect(() => {
    setCurrentHotspots(hotspots[index].hotspots[hotspots[index].media[mediaIndex].contentful_id])
  }, [index, mediaIndex])

  return (
    <Container background={getColorVariable(backgroundColor || 'White')}>
      <ContentWrapper>
        <Hotspots data={hotspots[index]} key={index} onMediaIndexChange={(index) => setMediaIndex(index)} />

        <Buttons>
          {hotspots.map(({ title }, i) => (
            <Button
              key={title}
              className={index === i ? 'is-active' : ''}
              onClick={() => setIndex(i)}
            >
              {title}
            </Button>
          ))}
        </Buttons>

        <SelectHolder>
          <Select onChange={({ target: { value } }) => setIndex(parseInt(value, 10))} value={index}>
            {hotspots.map(({ title }, i) => (
              <option key={title} value={i}>
                {title}
              </option>
            ))}
          </Select>
          <Arrow />
        </SelectHolder>

        <HotspotsAccordion texts={currentHotspots} />
      </ContentWrapper>
    </Container>
  )
}

HotspotsContainer.propTypes = {
  hotspots: PropTypes.arrayOf(PropTypes.object),
  backgroundColor: PropTypes.string,
}

export default HotspotsContainer

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Img from 'gatsby-image'
import ContentWrapper from './content-wrapper'
import SectionTop from './section-top'
import ActiveInView from './active-in-view'
import ButtonLink from './button-link'
import { getColorVariable } from '../utils'
import { fadeInUp } from '../styles/animations'

const Container = styled.section`
  background: white;
  border-radius: 2rem 2rem 0 0;
  margin-top: -2rem;
  padding: 5rem 0;

  ${({ background }) => `background: var(--color-${background});`}

  ${({ background }) =>
    background === 'primary-1' &&
    `
    color: white;

    h2, h3 {
      color: inherit;
    }

  `}

  h3 {
    font-size: 1.9rem;
    line-height: 1.17;
    margin: 0 0 1em;
  }

  @media (min-width: 48em) {
    padding: 8rem 0;
  }

  @media (min-width: 62.5em) {
    padding: 11rem 0 9rem;
  }
`

const StackGrid = styled.div`
  display: grid;
  grid-auto-flow: dense;
  margin: 4rem auto;
  display: grid;
  grid-gap: 4rem;

  @media (min-width: 48em) {
    grid-template-columns: 1fr 1fr;
    padding: 0 4rem;
    margin: 8rem auto 10rem;

    > div:first-child {
      grid-column-start: 2;
    }
  }

  @media (min-width: 62.5em) {
    padding: 0 8rem;
  }
`

const StackText = styled.div`
  max-width: 42rem;
  text-align: left;
  margin: 0 auto;
  padding: 1rem 0 0 0;

  @media (min-width: 48em) {
    text-align: left;
    margin: 0;
  }
`

const BorderedImage = styled(Img)`
  border-radius: 2rem;
`

const FadeImage = styled(ActiveInView)`
  opacity: 0;

  .no-js & {
    opacity: 1;
  }

  &.is-active {
    animation: ${fadeInUp} 500ms forwards;
  }
`

const StackImage1 = styled(FadeImage)`
  width: 85%;

  @media (min-width: 48em) {
    width: 100%;
  }
`

const StackImage2 = styled(FadeImage)`
  width: 85%;
  margin: -30% 0 0 auto;

  @media (min-width: 48em) {
    width: 79%;
    margin: -30% -4rem -30% auto;
  }

  @media (min-width: 62.5em) {
    margin: -30% -8rem -20% auto;
  }

  @media (min-width: 100em) {
    margin: -30% -8rem -18% auto;
  }

  @media all and (-ms-high-contrast: none) {
    width: 65%;
  }
`

const ColumnGrid = styled.div`
  margin-top: 4rem;

  @media (min-width: 48em) {
    margin-top: 7rem;
    display: grid;
    grid-column-gap: 4rem;
    grid-auto-flow: dense;
    justify-content: space-between;
    grid-template-columns: minmax(100px, 64.5rem) minmax(100px, 53rem);

    > div:first-child {
      display: flex;
      flex-direction: column-reverse;
      grid-column: 2;
      align-self: flex-start;
    }
  }
`

const ColumnText = styled.div`
  max-width: 53.3rem;
  margin: 4rem auto;

  @media (min-width: 48em) {
    margin: 0;
  }
`

const ColumnText1 = styled(ColumnText)`
  @media (min-width: 48em) {
    padding: 4rem 0;
  }

  @media (min-width: 62.5em) {
    padding: 5rem 0 8rem;
  }
`

const ColumnText2 = styled(ColumnText)`
  @media (min-width: 48em) {
    padding: 7rem 0 0;
  }
`

const InfoSection = ({
  mediaLayout,
  title,
  text1,
  text2,
  images,
  callToActionText,
  callToActionLink,
  backgroundColor,
}) => {
  if (mediaLayout === 'Column') {
    images.map(({ fluid }) => {
      fluid.sizes = '(min-width: 87em) 35em, (min-width: 48em) 50vw, 100vw'
    })
  } else {
    images.map(({ fluid }) => {
      fluid.sizes = '(min-width: 87em) 35em, (min-width: 48em) 50vw, 80vw'
    })
  }

  return (
    <Container background={getColorVariable(backgroundColor || 'White')}>
      <ContentWrapper>
        {mediaLayout !== 'Column' ? (
          <>
            <SectionTop title={title} text={text1} />
            <StackGrid>
              <div>
                <StackImage1>
                  <BorderedImage fluid={{ ...images[0].fluid, aspectRatio: 533 / 464 }} />
                </StackImage1>
                {images.length > 1 && (
                  <StackImage2>
                    <BorderedImage fluid={{ ...images[1].fluid, aspectRatio: 421 / 324 }} />
                  </StackImage2>
                )}
              </div>
              <div>
                <StackText>
                  {renderRichText(text2)}

                  {callToActionLink && callToActionText && (
                    <ButtonLink href={callToActionLink}>{callToActionText}</ButtonLink>
                  )}
                </StackText>
              </div>
            </StackGrid>
          </>
        ) : (
          <>
            <SectionTop title={title} />

            <ColumnGrid>
              <div>
                {images.length > 1 && (
                  <FadeImage>
                    <BorderedImage fluid={{ ...images[1].fluid, aspectRatio: 533 / 516 }} />
                  </FadeImage>
                )}
                <ColumnText1>{renderRichText(text1)}</ColumnText1>
              </div>

              <div>
                <FadeImage>
                  <BorderedImage fluid={{ ...images[0].fluid, aspectRatio: 645 / 550 }} />
                </FadeImage>

                <ColumnText2>
                  {renderRichText(text2)}

                  {callToActionLink && callToActionText && (
                    <ButtonLink href={callToActionLink}>{callToActionText}</ButtonLink>
                  )}
                </ColumnText2>
              </div>
            </ColumnGrid>
          </>
        )}
      </ContentWrapper>
    </Container>
  )
}

InfoSection.propTypes = {
  mediaLayout: PropTypes.string,
  title: PropTypes.string.isRequired,
  text1: PropTypes.object,
  text2: PropTypes.object,
  images: PropTypes.arrayOf(PropTypes.object),
  callToActionText: PropTypes.string,
  callToActionLink: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default InfoSection

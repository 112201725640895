import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GalleryInner } from './gallery'
import { getColorVariable } from '../utils'

const Container = styled.div`
  border-radius: 2rem;
  overflow: hidden;
  margin: 0 0 2rem;
  display: flex;
  flex-direction: column;

  ${({ background }) => background && `background: var(--color-${background});`}

  @media (min-width: 48em) {
    min-height: 30rem;
    flex-direction: row;
    margin: 0 0 2.4rem;

    ${({ reverse }) => reverse && `flex-direction: row-reverse;`}

    &:not(:last-child) {
      ${({ extraMargin }) => extraMargin && `margin-bottom: 8rem;`}
    }
  }

  @media (min-width: 62.5em) {
    min-height: 38.8rem;
  }
`

const Fade = styled.div`
  height: 10rem;
  width: 100%;
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 100%;
  background: linear-gradient(to top, rgba(251, 251, 251, 1) 20%, rgba(255, 255, 255, 0));

  ${({ color }) =>
    color === 'Sand' &&
    `
      background: linear-gradient(to top, rgba(237, 229, 226, 1) 20%, rgba(237, 229, 226, 0));
    `}

  @media (min-width: 48em) {
    width: 10rem;
    height: 100%;
    bottom: 0;
    left: auto;
    right: 100%;
    background: linear-gradient(-90deg, rgba(251, 251, 251, 1) 20%, rgba(251, 251, 251, 0));

    ${({ color }) =>
      color === 'Sand' &&
      `background: linear-gradient(-90deg, rgba(237, 229, 226, 1) 20%, rgba(237, 229, 226, 0));`}

    ${({ reverse }) =>
      reverse &&
      `
        left: 100%;
        right: auto;
        background: linear-gradient(90deg, rgba(251, 251, 251, 1) 20%, rgba(251, 251, 251, 0));
    `}

    ${({ reverse, color }) =>
      reverse &&
      color === 'Sand' &&
      `
        background: linear-gradient(90deg, rgba(237, 229, 226, 1) 20%, rgba(237, 229, 226, 0));
    `}
  }
`

const Text = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 2rem 3rem;
  position: relative;
  white-space: pre-wrap;

  p {
    margin: 0;
  }

  p + p {
    margin-top: 1.2em;
  }

  @media (min-width: 48em) {
    width: 50%;
    padding: 4rem;
    position: relative;

    ${({ wide }) => wide && `width: 100%; padding: 9rem 8rem;`}

    > div {
      width: 100%;
      max-width: 52rem;
      margin: 0 auto;
    }
  }

  @media (min-width: 82em) {
    //padding: 4rem 6rem 4rem 8rem;
  }
`

const ImageHolder = styled.div`
  .gatsby-image-wrapper {
    height: 100%;

    > div:first-child {
      padding-bottom: 50% !important;

      ${({ height }) => height === 'Medium' && `padding-bottom: 80% !important;`}
      ${({ height }) => height === 'Tall' && `padding-bottom: 120% !important;`}
    }
  }

  ${({ cornered }) =>
    cornered &&
    `
    border-radius: 2rem;
    overflow: hidden;
  `}

  @media (min-width: 48em) {
    width: 50%;
    position: relative;
  }
`

const Image = styled(Img)`
  height: 100%;
`

const Heading = styled.h2`
  font-size: 2rem;
  line-height: 1.125;
  margin: 0 0 1em;

  @media (min-width: 62.5em) {
    font-size: 2.4rem;
  }
`

const Card = ({
  title,
  text,
  rightAdjustMedia,
  backgroundColor,
  mediaHeight,
  fadeMedia,
  media,
  hideHeading,
}) => {
  return (
    <Container
      reverse={rightAdjustMedia}
      background={getColorVariable(backgroundColor)}
      extraMargin={!backgroundColor || backgroundColor === 'Transparent'}
    >
      {media && (
        <ImageHolder
          height={mediaHeight}
          cornered={!backgroundColor || backgroundColor === 'Transparent'}
        >
          {(media.length === 1 || fadeMedia) && <Image fluid={{ ...media[0].fluid }} />}

          {media.length > 1 && !fadeMedia && <GalleryInner images={media} />}
        </ImageHolder>
      )}

      <Text wide={!media}>
        <div>
          {!hideHeading && <Heading>{title}</Heading>}
          {renderRichText(text)}

          {fadeMedia && <Fade reverse={rightAdjustMedia} color={backgroundColor} />}
        </div>
      </Text>
    </Container>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object,
  rightAdjustMedia: PropTypes.bool,
  backgroundColor: PropTypes.string,
  mediaHeight: PropTypes.string,
  fadeMedia: PropTypes.bool,
  media: PropTypes.arrayOf(PropTypes.object),
  hideHeading: PropTypes.bool,
}

export default Card

import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Button from './button'

const ButtonLink = ({ href, children }) => {
  const external = href && href.indexOf('http') === 0

  return (
    <>
      {external ? (
        <>
          <Button as="a" href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </Button>
        </>
      ) : (
        <Button as={Link} to={href}>
          {children}
        </Button>
      )}
    </>
  )
}

ButtonLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default ButtonLink
